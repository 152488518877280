import React, { useRef } from 'react';
import './footer.css';
import logo from '../assets/logoLight.png'
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Footer = () => {
  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('#', '#', form.current, '#')
      .then((result) => {
          toast.success("Message sent successfully");
      }, (error) => {
          console.log(error.text);
          toast.error("Couldn't send message at this time");
      });
  };

  return (
    <div className="footer_content">
      <div className="footer">
        <div className="footer_left">
          <img
            src={logo}
            alt="logoImg"
            className="logo"
            onClick={() => navigate("/")}
          />
          <p>India's no - 1 online shopping platform</p>
          <img
            src="https://www.pngkey.com/png/full/664-6649096_18-payment-icons-01.png"
            alt="paymentImg"
            className="pay"
          />
        </div>
        <div className="footer_middle">
          <h3>Our Location:</h3>
          <p>
            Kamdhenu Commerz 1704, 17th Floor, Sector 14, Kharghar, Navi Mumbai:
            410210
          </p>
        </div>
        <div className="footer_right">
          <div className="contact_form">
            <h3>Contact Us</h3>
            <form ref={form} onSubmit={sendEmail}>
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Enter Name Here"
                  name="name"
                  required
                />
                <input
                  type="number"
                  placeholder="Enter Mobile Number Here"
                  name="mobile"
                  required
                />
                <input
                  type="email"
                  placeholder="Enter Email Here"
                  name="email"
                  required
                />
                <input
                  type="text"
                  placeholder="Enter Subject Here"
                  name="subject"
                  required
                />
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Enter Your Message Here"
                  required
                ></textarea>
              </div>
              <div className="contact_button">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <hr />
      <div className="copyright">
        <p>
          Frontend is covered by Finapsys Consultancy Pvt. Ltd. &#169; All
          Rights Reserved.
        </p>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Footer